<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-form v-model="valid">
        <div :class="containerClasses">
          <v-skeleton-loader
            v-if="$apollo.queries.getWebServiceUser.loading"
            class="mb-2"
            type="text@8"
          />
          <div v-else>
            <B2bCustomerSelect
              id="organization-select"
              v-model="b2bCustomerId"
              required
              rules="required"
              @returnObject="(value) => b2bCustomer = value"
            />
            <EkitabuTextInput
              id="b2b-discount-override"
              v-model="b2bDiscountOverride"
              class="mt-3"
              placeholder="0"
              :label="$t('couponManager.discount')"
              type="number"
              :rules="discountAmountRules"
              required
              max="100"
              min="1"
            />
            <v-switch
              v-model="isPdq"
              :label="$t('b2bOrders.isPdqOrder')"
              :small="$vuetify.breakpoint.xs"
              hide-details
            />
            <EkitabuTextInput
              v-if="isPdq"
              id="pdq-transaction-id"
              v-model="pdqTransactionId"
              class="mt-3"
              :label="$t('checkout.pdqTransactionId')"
              :placeholder="$t('checkout.pdqTransactionId')"
              required
              rules="required"
            />
            <AdminOrder
              class="mt-3"
              :is-cash-transaction="true"
              :show-event="false"
              :admin-order-details="adminOrderDetails"
              @updateAdminOrderDetails="setAdminOrderDetails"
            />
          </div>
        </div>
        <PlaceOrderButton
          :invalid="invalid"
          :shipping-info="shippingInfo"
          :school-info="schoolInfo"
          :is-pickup-order="isPickupOrder"
          :is-admin-order="true"
          :admin-order-details="adminOrderDetails"
          :pdq-transaction-id="pdqTransactionId"
          :b2b-customer-id="b2bCustomerId"
          :b2b-discount-override="Number(b2bDiscountOverride)"
        />
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import PlaceOrderButton from "@/views/checkout/CashPlaceOrderButton";
import { required, max, min } from "vee-validate/dist/rules";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";
import i18n from "@/i18n";
import gql from "graphql-tag";
import AdminOrder from "@/views/checkout/AdminOrder";
import EkitabuTextInput from "@/components/EkitabuTextInput.vue";
import B2bCustomerSelect from "@/components/inputs/B2bCustomerSelect.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: i18n.t("errors.required", { field: "{_field_}" }),
});

extend("max", {
  ...max,
  message: i18n.t("errors.maxLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("min", {
  ...min,
  message: i18n.t("errors.minLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

export default {
  name: "B2bOrderDetails",
  components: {
    PlaceOrderButton,
    ValidationObserver,
    AdminOrder,
    EkitabuTextInput,
    B2bCustomerSelect,
  },
  props: {
    isPrintOrder: {
      type: Boolean,
      default: false,
    },
    containerClasses: {
      type: String,
      default: "mt-3",
    },
  },
  data() {
    return {
      valid: false,
      shippingInfo: {
        altPhoneNumber: null,
        addressDetails: null,
        placeId: null,
        eventId: null,
      },
      schoolInfo: {
        schoolId: null,
        studentName: null,
        studentClass: null,
      },
      isPickupOrder: false,
      adminOrderDetails: {
        forEmail: null,
        forName: null,
        phoneNumber: null,
        event: null,
      },
      pdqTransactionId: null,
      b2bCustomerId: null,
      b2bCustomer: null,
      b2bDiscountOverride: null,
      isPdq: false
    };
  },
  computed: {
    discountAmountRules() {
      return "required|min_value:0|max_value:100";
    }
  },
  apollo: {
    getWebServiceUser: {
      query: gql`
        query getUserInfo {
          getWebServiceUser {
            id
            email
            familyName
            givenName
            phoneNumber
            emailVerified
            alternativePhoneNumber
          }
        }
      `,
    },
  },
  watch: {
    b2bCustomer(val) {
      this.b2bDiscountOverride = val?.customerDiscountPercentage;
      this.adminOrderDetails = {
        ...this.adminOrderDetails,
        forName: val?.customerName,
        forEmail: val?.customerEmail
      }
    }
  },
  methods: {
    setShippingInfo(shippingInfo) {
      this.shippingInfo = shippingInfo;
    },
    setSchoolInfo(schoolInfo) {
      this.schoolInfo = schoolInfo;
    },
    setIsPickupOrder(isPickupOrder) {
      this.isPickupOrder = isPickupOrder;
    },
    setDeliveryOptionsValid(isValid) {
      this.deliveryOptionsValid = isValid;
    },
    setAdminOrderDetails(adminOrderDetails) {
      this.adminOrderDetails = adminOrderDetails;
    },
  },
};
</script>
