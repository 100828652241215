import gql from "graphql-tag";

export default {
  updateProfileInfo: gql`
    mutation UpdateWebServiceUser($input: updateWebServiceUserInput!) {
      updateWebServiceUser(input: $input) {
        webServiceUser {
          id
          email
          familyName
          givenName
          phoneNumber
          emailVerified
          alternativePhoneNumber
          address
          additionalAddressInfo
          country
          county
          subCounty
        }
      }
    }
  `,

  resetPassword: gql`
  mutation SendResetPasswordRequest {
      resetPasswordRequestWebServiceUser(input: {}) {
        webServiceUser {
          id
          resetPasswordLink
        }
      }
    }
  `,

  getUserInfo: gql`
    query getUserInfo {
      getWebServiceUser {
        id
        email
        familyName
        givenName
        phoneNumber
        emailVerified
      }
    }
  `,

  getUserRoles: gql`
    query GetUserRoles{
      getWebServiceUser {
        id
        roles
      }
    }
  `,

  searchUsers: gql`
    query SearchUsers($search: String!, $role: String) {
      searchWebServiceUsers(search: $search, role: $role, page: 0, perPage: 10) {
        collection {
          id
          userId
          email
          givenName
          familyName
        }
      }
    }
  `,

  getUsers: gql`
    query getPaginatedWebServiceUsers($page: Int!, $perPage: Int!, $sort: String, $filterQuery: String, $hasOrders: Boolean, $filterOrderDateStart: String, $filterOrderDateEnd: String) {
      getPaginatedWebServiceUsers(page: $page, perPage: $perPage, sort: $sort, filterQuery: $filterQuery, hasOrders: $hasOrders, filterOrderDateStart: $filterOrderDateStart, filterOrderDateEnd: $filterOrderDateEnd) {
        collection {
          id
          roles
          email
          userId
          createdAt
          emailVerified
          familyName
          givenName
          nickname
          name
          phoneNumber
          alternativePhoneNumber
          lastLogin
          signupCountry
          signupIp
          completedOrderCount
          lastOrderDate
        }
        paginationInfo {
          itemsPerPage
          lastPage
          totalCount
        }
      }
    }
  `,
}