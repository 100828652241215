<template>
  <v-container>
    <div class="admin-content-container pa-3 mb-6">
      <admin-info />
    </div>
    <div class="admin-content-container pa-3">
      <v-row
        class="admin-header-container pb-3"
        align="center"
        justify="space-between"
        no-gutters
      >
        <h3>{{ $t("admin.reports") }}</h3>
        <router-link
          v-if="$auth.isAuthenticated"
          class="admin-header-link"
          :to="{ name: 'transactionReport' }"
        >
          {{ $t("admin.viewAll") }}
        </router-link>
      </v-row>
      <v-btn
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-6"
        :to="{ name: 'transactionReport' }"
      >
        {{ $t('admin.transactionReport') }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-2"
        :to="{ name: 'couponManager' }"
      >
        {{ $t('admin.couponManager') }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-2"
        :to="{ name: 'userDashboard' }"
      >
        {{ $t('admin.userDashboard') }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-2"
        :to="{ name: 'customerDashboard' }"
      >
        {{ $t('admin.customerDashboard') }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-2"
        :to="{ name: 'eventManager' }"
      >
        {{ $t('admin.eventManager') }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-2"
        :to="{ name: 'eventDashboard' }"
      >
        {{ $t('admin.eventDashboard') }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-2"
        :to="{ name: 'schoolBookListManager' }"
      >
        {{ $t('admin.schoolBookListManager') }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-2"
        :to="{ name: 'b2bCustomers' }"
      >
        {{ $t('admin.b2bCustomers') }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import AdminInfo from '@/views/admin/AdminInfo';

// TODO: This will need to be secured via a backend permission/role check when implemented
//   Github Issue: https://github.com/ekitabu-LLC/shop-api/issues/67

export default {
  name: "AdminDashboard",
  components: {
    AdminInfo
  },
  data() {
    return {
      // TODO: Replace when backend auth0 profile integration is complete
      //  Github Issue: https://github.com/ekitabu-LLC/shop-web/issues/35
      phoneNumber: "123-456-7890",
      password: "**********",
    };
  },
};
</script>

<style scoped>
.admin-content-container {
  background-color: var(--v-lightestAccent-base);
}
</style>
