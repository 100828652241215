import gql from "graphql-tag";

export default {
  orderDetailsFragment: gql`
    fragment OrderDetails on Order {
      __typename
      id
      _id
      totalLineItemsPrice
      totalDiscount
      subtotalPrice
      shippingFee
      totalTax
      totalPrice
      updatedAt
      confirmed
      currency {
        id
        _id
      }
      orderCoupon {
        id
        code
        description
        discount
      }
      orderLines(first: 1000) {
        __typename
        edges {
          node {
            id
            productId
            name
            preTaxPrice
            basePrice
            price
            discount
            quantity
            format
            authorFirstName
            authorLastName
            baseCurrency {
              id
              _id
            }
          }
        }
      }
    }
  `,
  orderAdminDetailsFragment: gql`
    fragment OrderAdminDetails on Order {
      id
      _id
      customerId
      customerEmail
      confirmed
      customerLocale
      updatedAt
      totalLineItemsPriceSet
      subtotalPriceSet
      totalDiscountSet
      totalTaxSet
      totalPriceSet
      presentmentCurrency {
        id
        _id
      }
      currency {
        id
        _id
      }
      orderCoupon {
        id
        code
      }
      taxRate {
        id
        _id
        rate
      }
      safaricomMpesaRequests {
        id
        merchantRequestId
        resultCode
        msidsn
        succeededAt
      }
      dpoPayments {
        id
        transactionReference
        resultCode
      }
      cashPayments {
        id
        succeededAt
        pdqTransactionId
      }
      wholesalePayments {
        id
        succeededAt
        amount
        currency {
          id
        }
        pdqTransactionId
        discountPercentage
        b2bCustomer {
          id
          customerId
          organizationName
          customerName
          customerEmail
          customerDiscountPercentage
        }
      },
      momoMtnPayments {
        id
        transactionReference
        resultCode
        succeededAt
        msidsn
      }
      adminOrder {
        id
        _id
        forUserFullName
        forUserEmail
        forUserPhone
        adminUserId
        adminEmail
        adminFullName
        claimedAt
        createdAt
        updatedAt
      }
      shippingFeeSet
      orderFulfillment {
        id
        fulfilledAt
        addressLine1
        addressLine2
        addressPostalCode
        phoneNumber
        altPhoneNumber
        school {
          id
          name
        }
        studentName
        studentClass
        notes
        createdAt
      }
      event {
        id
        name
      }
      orderLines {
        edges {
          node {
            id
            _id
            name
            publisher
            productId
            basePrice
            baseCurrency {
              id
              _id
            }
            preTaxPriceSet
            priceSet
            discountSet
            quantity
            format
          }
        }
      }
    }
  `,
};
