<template>
  <div>
    <v-container
      id="orderDetails"
      fluid
      :style="`width: 100%; max-width: ${maxContainerWidth}px`"
    >
      <div class="lightestAccent d-flex flex-column align-start px-10 py-8">
        <div class="info-item mb-8">
          <h4 class="mr-1">
            {{ $t("transactionReports.orderId") }}:
          </h4>
          <span style="color: var(--v-primary-base)">{{ order._id }}</span>
        </div>

        <div
          class="d-flex flex-wrap mb-8"
          style="gap: 50px"
        >
          <div class="order-info-box d-flex">
            <div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.baseCurrency") }}:
                </h4>
                <span>{{ order.presentmentCurrency._id }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.totalPrice") }}:
                </h4>
                <span>{{ order.totalPriceSet }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.couponUsed") }}:
                </h4>
                <span>{{ order?.orderCoupon?.code ?? $t("common.none") }}</span>
              </div>
            </div>
            <div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.basePrice") }}:
                </h4>
                <span>{{ order.totalLineItemsPriceSet }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.orderCurrency") }}:
                </h4>
                <span>{{ order.currency._id }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("couponManager.discount") }}:
                </h4>
                <span>{{ order.totalDiscountSet ?? 0 }}</span>
              </div>
            </div>
            <div>
              <div
                v-if="order?.event"
                class="info-item"
              >
                <h4 class="mr-2">
                  {{ $t("eventManager.event") }}:
                </h4>
                <span>{{ order.event.name }}</span>
              </div>
              <div
                v-if="order?.shippingFeeSet"
                class="info-item"
              >
                <h4 class="mr-2">
                  {{ $t("csvHeaders.shippingFee") }}:
                </h4>
                <span>{{ order?.shippingFeeSet }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.taxRegion") }}:
                </h4>
                <span>{{ order.taxRate._id }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.taxPaid") }}:
                </h4>
                <span>{{ order.totalTaxSet }}</span>
              </div>
            </div>
          </div>

          <div class="order-info-box d-flex">
            <div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("csvHeaders.providerTransactionId") }}:
                </h4>
                <span>{{ providerDetails.transactionId }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.paymentMethod") }}:
                </h4>
                <span>{{ $t("paymentMethods." + (providerDetails?.provider ?? "none")) }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("transactionReports.paymentPhone") }}:
                </h4>
                <span>{{ providerDetails?.msidsn ?? $t("common.none") }}</span>
              </div>
              <div
                v-if="cashPayment?.pdqTransactionId"
                class="info-item"
              >
                <h4 class="mr-2">
                  {{ $t("transactionReports.pdqTransactionId") }}:
                </h4>
                <span>{{ cashPayment?.pdqTransactionId }}</span>
              </div>
            </div>
          </div>

          <div
            v-if="wholesaleOrderDetails"
            class="order-info-box d-flex flex-wrap"
          >
            <div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("b2bOrders.status") }}:
                </h4>
                <span class="text-uppercase">{{
                  wholesaleOrderDetails?.succeededAt
                    ? $t("b2bOrders.paid")
                    : $t("b2bOrders.pending")
                }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("b2bOrders.pdqOrPaybill") }}:
                </h4>
                <span>{{
                  wholesaleOrderDetails?.pdqTransactionId
                    ? $t("b2bOrders.pdq")
                    : $t("b2bOrders.paybill")
                }}</span>
              </div>
              <div
                v-if="wholesaleOrderDetails?.pdqTransactionId"
                class="info-item"
              >
                <h4 class="mr-2">
                  {{ $t("b2bOrders.pdqId") }}:
                </h4>
                <span>{{ wholesaleOrderDetails.pdqTransactionId ?? $t("common.none") }}</span>
              </div>
            </div>
            <div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("b2bOrders.discountPercentage") }}:
                </h4>
                <span>
                  {{ wholesaleOrderDetails.discountPercentage ?? $t("common.none") }}
                </span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("b2bOrders.customerId") }}:
                </h4>
                <span>
                  {{ wholesaleOrderDetails.b2bCustomer?.customerId ?? $t("common.none") }}
                </span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("b2bOrders.organizationName") }}:
                </h4>
                <span>{{ wholesaleOrderDetails.b2bCustomer?.organizationName ?? $t("common.none") }}</span>
              </div>
            </div>
            <div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("b2bOrders.customerEmail") }}:
                </h4>
                <span>{{ wholesaleOrderDetails.b2bCustomer?.customerEmail ?? $t("common.none") }}</span>
              </div>
              <div class="info-item">
                <h4 class="mr-2">
                  {{ $t("b2bOrders.customerDiscountPercentage") }}:
                </h4>
                <span>{{ wholesaleOrderDetails.b2bCustomer ?.customerDiscountPercentage ?? $t("common.none") }}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="fulfillmentDetails"
          class="mb-8"
          style="width: 100%"
        >
          <h4>
            {{ $t("transactionReports.fulfillmentDetails") }}
          </h4>
          <table class="order-details-table">
            <thead>
              <tr>
                <th>
                  {{ $t("checkout.delivery.method") }}
                </th>
                <th>
                  {{ $t("profile.addressDetails") }}
                </th>
                <th>
                  {{ $t("csvHeaders.dateClaimed") }}
                </th>
                <th>
                  {{ $t("checkout.delivery.orderNotes") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    order?.shippingFeeSet ?
                      $t("checkout.delivery.deliveryMethods.delivery") : order?.school ?
                        $t("checkout.delivery.deliveryMethods.school") :
                        $t("checkout.delivery.deliveryMethods.pickup")
                  }}
                </td>
                <td>
                  {{ fulfillmentDetails.addressLine2 ?? $t("common.none") }}
                </td>
                <td>
                  {{ fulfillmentDetails.claimedAt ?? $t("common.none") }}
                </td>
                <td>
                  <span v-if="fulfillmentDetails.notes?.length > 0">
                    <p
                      v-for="note in fulfillmentDetails.notes"
                      :key="note"
                    >
                      {{ note }}
                    </p>
                  </span>
                  <span v-else>{{ $t("common.none") }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="width: 100%">
          <h4 class="text-left">
            {{ $t("transactionReports.orderDetails") }}
          </h4>
          <table class="order-details-table">
            <thead>
              <tr>
                <th>
                  {{ $t("transactionReports.bookId") }}
                </th>
                <th>
                  {{ $t("csvHeaders.bookName") }}
                </th>
                <th>
                  {{ $t("transactionReports.publisher") }}
                </th>
                <th>
                  {{ $t("csvHeaders.format") }}
                </th>
                <th>
                  {{ $t("transactionReports.quantity") }}
                </th>
                <th>
                  {{ $t("transactionReports.pricePaid") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="orderLine in orderLines"
                :key="orderLine.id"
              >
                <td>
                  {{ orderLine.productId }}
                </td>
                <td>
                  {{ orderLine.name }}
                </td>
                <td>
                  {{ orderLine.publisher }}
                </td>
                <td>
                  {{ $t("formats." + cleanFormatType(orderLine.format)) }}
                </td>
                <td>
                  {{ orderLine.quantity }}
                </td>
                <td>
                  {{ orderLine.priceSet }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="3"
                  class="transparent"
                />
                <td class="lightAccent total">
                  <strong>{{ $t("checkout.total") }}</strong>
                </td>
                <td class="lightAccent">
                  {{ totalQuantity }}
                </td>
                <td class="lightAccent">
                  {{ order.totalPriceSet - totalShippingFee }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          class="ml-auto mt-8 no-print"
          color="secondary"
          tile
          elevation="0"
          @click="printToPDF"
        >
          {{ $t("common.printToPDF") }}
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import { cleanFormatType } from "@/helpers/generalFunctions";

export default {
  name: "OrderDetails",
  props: {
    order: {
      type: Object,
      required: true,
    },
    providerDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    maxContainerWidth() {
      return 1600;
    },
    fulfillmentDetails() {
      return this.order.orderFulfillment;
    },
    wholesaleOrderDetails() {
      return this.order?.wholesalePayments?.find((item) => !!item?.succeededAt);
    },
    orderLines() {
      return this.order.orderLines.edges.map((edge) => edge.node);
    },
    totalQuantity() {
      return this.orderLines.reduce((acc, line) => acc + line.quantity, 0);
    },
    totalShippingFee() {
      return (
        Number(this.order.shippingFeeSet) +
        Number(this.order.shippingFeeSet) * this.order.taxRate.rate
      );
    },
    cashPayment() {
      return this.order?.cashPayments?.find(
        (payment) => payment.succeededAt != null
      );
    },
  },
  methods: {
    cleanFormatType,
    printToPDF() {
      window.print();
    },
  },
};
</script>

<style scoped lang="scss">
.order-info-box {
  gap: 40px;
  border: 1px solid var(--v-lightAccent-base);
  padding: 10px;
}

.info-item {
  display: flex;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 12px;
  color: var(--v-primaryText-base);
}

.order-info-box > div .info-item:last-of-type {
  margin-bottom: 0;
}

.order-info-row {
  .col > div {
    margin-right: 40px;
    margin-bottom: 10px;
  }
}
.order-details-table {
  width: 100%;
  border-collapse: separate;
  border: none;
  border-spacing: 0 5px;
  th,
  td {
    background-color: var(--v-backgroundSecondary-base);
    color: var(--v-tertiaryText-base);
    padding: 5px 10px;
    text-align: left;
    font-weight: bold;
    font-size: 12px;
  }

  th,
  td.total {
    color: var(--v-primaryText-base);
  }
}
</style>
