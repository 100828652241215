<template>
  <div>
    <div class="header-border pb-1 mb-3">
      <h2>{{ $t('b2bCustomerManager.customerList') }}</h2>
      <v-progress-linear
        v-if="$apollo.loading"
        color="primary"
        indeterminate
      />
    </div>
    <v-list
      class="customer-list"
    >
      <v-list-item-group
        v-if="b2bCustomers?.length > 0"
        v-model="selectedCustomerId"
      >
        <v-list-item
          v-for="customer in b2bCustomers"
          :key="customer.id"
          :value="customer.id"
        >
          <v-list-item-title>
            {{ customer.organizationName }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-item
        v-else
      >
        <v-list-item-title>{{ $t('b2bCustomerManager.noCustomers') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "B2bCustomerList",
  components: {
  },
  data() {
    return {
      selected: null,
      selectedCustomerId: null,
    };
  },
  apollo: {
    b2bCustomers: {
      query: gql`
        query B2bCustomers {
          b2bCustomers {
            id
            customerId
            organizationName
            customerName
            customerEmail
            customerDiscountPercentage
          }
        }
      `,
    },
  },
  watch: {
    selectedCustomerId(val) {
      this.$emit("customerSelected", val);
    },
  },
};
</script>

<style scoped>
.customer-list {
  max-height: 500px;
  overflow-y: auto;
}
</style>