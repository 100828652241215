<template>
  <ApolloMutation
    :mutation="(gql) => placeOrderQuery"
    :variables="{
      input: {
        id: orderId,
        phoneNumber: adminOrderDetails?.phoneNumber,
        alternativePhoneNumber: shippingInfo?.altPhoneNumber,
        placeId: shippingInfo?.placeId,
        addressDetails: shippingInfo?.addressDetails,
        schoolId: schoolInfo?.schoolId,
        studentName: schoolInfo?.studentName,
        studentClass: schoolInfo?.studentClass,
        forFullName: adminOrderDetails?.forName,
        forEmail: adminOrderDetails?.forEmail,
        eventId: adminOrderDetails?.eventId,
        ...(pdqTransactionId && {
          pdqTransactionId: pdqTransactionId,
        }),
        ...(b2bCustomerId && {
          b2bCustomerId,
          b2bDiscountOverride 
        })
      },
    }"
    @done="onDone"
    @error="() => { loading = false; }"
  >
    <template #default="{ mutate }">
      <PrimaryButton
        id="submit"
        class="text-uppercase"
        block
        :disabled="loading || invalid"
        :loading="loading"
        @click="() => {
          loading = true;
          mutate();
        }"
      >
        {{ $t("checkout.form.placeOrder") }}
        <v-icon
          class="ml-2 has-glow"
          x-small
        >
          $triangleRight
        </v-icon>
      </PrimaryButton>
    </template>
  </ApolloMutation>
</template>

<script>
  import OrderQueries from "@/graphql/OrderQueries";
  import BookQueries from "@/graphql/BookQueries";
  import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

  export default ({
    name: "PlaceOrderButton",
    components: {
      PrimaryButton,
    },
    props: {
      invalid: {
        type: Boolean,
        default: false,
      },
      shippingInfo: {
        type: Object,
        default: null,
      },
      schoolInfo: {
        type: Object,
        default: null,
      },
      isPickupOrder: {
        type: Boolean,
        default: false,
      },
      adminOrderDetails: {
        type: Object,
        default: null,
      },
      pdqTransactionId: {
        type: String,
        default: null,
      },
      b2bCustomerId: {
        type: String,
        default: null
      },
      b2bDiscountOverride: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        orderId: this.$store.state.cart.currentCart,
        loading: false,
      };
    },
    computed: {
      placeOrderQuery() {
        return OrderQueries.placeCashOrder;
      },
    },
    methods: {
      async onDone({ data }) {
        if (data.placeCashOrder.order.confirmed) {
          await this.$apollo.query({
            query: BookQueries.getUserBookshelf,
            fetchPolicy: "network-only",
          });
          this.$store.commit("setCurrentCart", null);
          this.loading = false;
          this.$router.push({
            name: "confirmed",
            params: {
              id: this.orderId,
            },
          });
        }
      },
    },
  });
</script>