<template>
  <ApolloQuery
    :query="(gql) => searchUsersQuery"
    :variables="{
      search: searchText,
      ...(role ? {role: role} : {})
    }"
    :skip="searchText.length < 3"
  >
    <template #default="{ result: { error, data }, isLoading }">
      <apollo-error-alert
        :error="error"
      />
      <search-autocomplete
        :id="id"
        v-model="selected"
        :rules="rules"
        :items="data?.searchWebServiceUsers?.collection ?? []"
        :label="$t('couponManager.forUser')"
        :placeholder="placeholder"
        :use-checkbox="showCheck"
        item-value="userId"
        :select-text="selectText"
        :loading="!!isLoading"
        :filter="customFilter"
        :return-object="returnObject"
        :required="required"
        @searchChanged="onSearchChanged"
      />
      <div
        v-if="selectedId"
      >
        <h4>{{ $t('couponManager.currentUser') }}</h4>
        <span>{{ selectedId }}</span>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import SearchAutocomplete from "@/components/SearchAutocomplete";
import UserMutations from "@/graphql/UserMutations";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import i18n from "@/i18n";

export default {
  name: "UserSearch",
  components: {
    SearchAutocomplete,
    ApolloErrorAlert,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showCheck: {
      type: Boolean,
      default: true
    },
    role: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: i18n.t('admin.searchUsers')
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: "",
      selected: null,
      selectedId: this.value,
    };
  },
  computed: {
    searchUsersQuery() {
      return UserMutations.searchUsers;
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        this.selectedId = val?.userId ?? val;
      }
      
      this.$emit("returnObject", val)
    },
    selectedId(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.selectedId = val;
      if (this.selected?.userId != val) {
        this.selected = null;
      }
    },
  },
  methods: {
    onSearchChanged(searchText) {
      this.searchText = searchText;
    },
    customFilter (item, queryText, itemText) {
      if (this.searchText == null || this.searchText == "" || this.searchText.length < 3) return false;

      const textOne = item.givenName.toLowerCase();
      const textTwo = item.familyName.toLowerCase();
      const textThree = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1;
    },
    selectText(item) {
      return this.$t('common.fullName', { firstname: item.givenName, lastname: item.familyName }) + " (" + item.email + ")";
    }
  },
};
</script>