<template>
  <div>
    <div class="header-border pb-1 mb-3">
      <h2>{{ $t(id ? 'b2bCustomerManager.update' : 'b2bCustomerManager.create') }}</h2>
      <v-progress-linear
        v-if="$apollo.loading"
        color="primary"
        indeterminate
      />
    </div>
    <apollo-mutation
      :mutation="id ? updateB2bCustomerMutation : createB2bCustomerMutation"
      :variables="{ input: {
        ...(id ? { id: id } : {}),
        customerId: customerId,
        organizationName: organizationName,
        customerName: customerName,
        customerEmail: customerEmail,
        customerDiscountPercentage: Number(customerDiscountPercentage),
      }}"
      :update="onDone"
    >
      <template #default="{ mutate, loading, error }">
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
        >
          <EkitabuTextInput
            id="organization-name"
            v-model="organizationName"
            class="fill-width"
            :label="$t('b2bOrders.organizationName')"
            :placeholder="$t('b2bOrders.organizationName')"
            rules="required|min:3"
            required
            :disabled="loading || $apollo.loading"
          />
          <EkitabuTextInput
            id="b2b-discount-override"
            v-model="customerDiscountPercentage"
            class="mt-3"
            placeholder="0"
            :label="$t('b2bCustomerManager.defaultDiscount')"
            type="number"
            rules="required|min_value:0|max_value:100"
            required
            max="100"
            min="1"
          />
          <UserSearch
            id="customer-id"
            v-model="customerId"
            class="mt-3"
            :show-check="false"
            required
            :role="b2bRole"
            :placeholder="$t('b2bCustomerManager.searchUsers')"
            :return-object="true"
            @returnObject="customer = $event"
          />
          <EkitabuTextInput
            id="customer-email"
            v-model="customerEmail"
            class="mt-3"
            :placeholder="$t('b2bOrders.customerEmail')"
            :label="$t('b2bOrders.customerEmail')"
            rules="required|min:3"
            required
          />
          <EkitabuTextInput
            id="customer-name"
            v-model="customerName"
            class="mt-3"
            :placeholder="$t('b2bOrders.customerName')"
            :label="$t('b2bOrders.customerName')"
            rules="required|min:3"
            required
          />
          <v-btn
            class="mt-3"
            tile
            color="primary"
            elevation="0"
            :disabled="!valid || !customerId || loading || $apollo.loading || !isChanged"
            :loading="loading || $apollo.loading"
            block
            @click="mutate"
          >
            {{ id ? $t('b2bCustomerManager.update') : $t('b2bCustomerManager.create') }}
          </v-btn>
        </validation-observer>
      </template>
    </apollo-mutation>
    <SuccessSnackbar
      :value="showSnackbar"
      :message="id ? $t('b2bCustomerManager.updated') : $t('b2bCustomerManager.created')"
    />
  </div>
</template>

<script>
import EkitabuTextInput from '@/components/EkitabuTextInput';
import { required, min, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate'
import i18n from '@/i18n';
import gql from 'graphql-tag';
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import B2bCustomerQueries from '@/graphql/B2bCustomerQueries';
import SuccessSnackbar from '@/components/SuccessSnackbar.vue';
import UserSearch from '@/components//UserSearch.vue';
import { USER_ROLES } from '@/utils/Const';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: i18n.t('errors.required', {field: '{_field_}'}),
})

extend('min', {
  ...min,
  message: i18n.t('errors.minLength', {field: '{_field_}', min: '{min}'}),
})

extend('max', {
  ...max,
  message: i18n.t('errors.maxLength', {field: '{_field_}', max: '{max}'}),
})

export default {
  name: "ManageB2bCustmerForm",
  components: {
    EkitabuTextInput,
    ValidationObserver,
    ApolloErrorAlert,
    SuccessSnackbar,
    UserSearch
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      customer: null,
      customerId: null,
      organizationName: null,
      customerName: null,
      customerEmail: null,
      customerDiscountPercentage: null,
      showSnackbar: false,
    };
  },
  apollo: {
    b2bCustomer: {
      query: gql`
        query B2bCustomer($id: ID!) {
          b2bCustomer(id: $id) {
            id
            customerId
            organizationName
            customerName
            customerEmail
            customerDiscountPercentage
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
      result({ data }) {
        this.updateCustomerData(data?.b2bCustomer);
      },
    },
  },
  computed: {
    b2bRole() {
      return USER_ROLES.B2B;
    },
    createB2bCustomerMutation() {
      return B2bCustomerQueries.createB2bCustomer;
    },
    updateB2bCustomerMutation() {
      return B2bCustomerQueries.updateB2bCustomer;
    },
    isChanged() {
      if (!this.b2bCustomer) {
        return true;
      }
      else {
        return(
          this.customerId !== this.b2bCustomer.customerId ||
          this.organizationName !== this.b2bCustomer.organizationName ||
          this.customerDiscountPercentage !== this.b2bCustomer.customerDiscountPercentage ||
          this.customerName !== this.b2bCustomer.customerName ||
          this.customerEmail !== this.b2bCustomer.customerEmail
        );
      }
    },
  },
  watch: {
    id(val) {
      if (!val) {
        this.updateCustomerData({});
      }
    },
    customer(val) {
      if (val?.email) {
        this.customerEmail = val.email
      }

      const givenName = val?.givenName == "" ? null : val?.givenName;
      const familyName = val?.familyName == "" ? null : val?.familyName;
      if (givenName || familyName) {
        this.customerName = givenName && familyName ?
          givenName + ' ' + familyName : givenName ? givenName :
          familyName
      }
    }
  },
  methods: {
    updateCustomerData(customer) {
      this.customerId = customer?.customerId
      this.organizationName = customer?.organizationName
      this.customerName = customer?.customerName
      this.customerEmail = customer?.customerEmail
      this.customerDiscountPercentage = customer?.customerDiscountPercentage
    },
    onDone(cache, { data }) {
      if (data?.createB2bCustomer?.b2bCustomer) {
        cache.modify({
          fields: {
            b2bCustomers(existingB2bCustomers) {
              return [...existingB2bCustomers, data?.createB2bCustomer?.b2bCustomer];
            },
          },
        });

        this.updateCustomerData({});
      }

      this.$refs.observer.reset();
      this.showSnackbar = true;
    },
  },
}
</script>