<template>
  <ekitabu-select
    id="tax-region-select"
    v-model="currentValue"
    v-bind="$attrs"
    :loading="$apollo.queries.b2bCustomers.loading"
    :disabled="$apollo.queries.b2bCustomers.loading"
    class="mt-2"
    :label="$t('transactionReports.b2bCustomer')"
    :placeholder="$t('transactionReports.b2bCustomer')"
    :items="b2bCustomers ?? []"
    item-text="organizationName"
    item-value="_id"
    clearable
  />
</template>

<script>
import EkitabuSelect from "@/components/EkitabuSelect";
import B2bCustomerQueries from "@/graphql/B2bCustomerQueries";

export default {
  name: "EkitabuB2bCustomerSelect",
  components: {
    EkitabuSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  apollo: {
    b2bCustomers: {
      query: B2bCustomerQueries.b2bCustomers
    },
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
    currentValue(val) {
      this.$emit("input", val);
      this.$emit("returnObject", this.b2bCustomers?.find((item) => item._id == val));
    },
  },
}
</script>
