import gql from "graphql-tag";

export default {
  b2bCustomers: gql`
    query B2bCustomers {
      b2bCustomers {
        id
        _id
        customerId
        customerName
        customerEmail
        organizationName
        customerDiscountPercentage
        wholesalePayments {
          id
          _id
        }
      }
    }
  `,
  createB2bCustomer: gql`
    mutation CreateB2BCustomer($input: createB2bCustomerInput!) {
      createB2bCustomer(input: $input) {
        b2bCustomer {
          id
          _id
          customerId
          customerName
          customerEmail
          organizationName
          customerDiscountPercentage
          wholesalePayments {
            id
            _id
          }
        }
      }
    }
  `,
  updateB2bCustomer: gql`
    mutation UpdateB2BCustomer($input: updateB2bCustomerInput!) {
      updateB2bCustomer(input: $input) {
        b2bCustomer {
          id
          _id
          customerId
          customerName
          customerEmail
          organizationName
          customerDiscountPercentage
          wholesalePayments {
            id
            _id
          }
        }
      }
    }
  `,
};
