<template>
  <div>
    <DataTableHeaderContainer>
      <h1>
        {{ $t('b2bCustomerManager.header') }}
      </h1>
    </DataTableHeaderContainer>
    <v-container class="b2b-customer-manager-container mt-6 pa-3">
      <v-row>
        <v-col>
          <B2bCustomerList @customerSelected="selectedCustomerId = $event" />
        </v-col>
        <v-col>
          <ManageB2bCustomerForm :id="selectedCustomerId" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DataTableHeaderContainer from '@/components/tables/DataTableHeaderContainer.vue';
import B2bCustomerList from '@/components/lists/B2bCustomerList.vue';
import ManageB2bCustomerForm from '@/components/forms/ManageB2bCustomerForm.vue';

export default {
  name: "B2bCustomerManager",
  components: {
    DataTableHeaderContainer,
    B2bCustomerList,
    ManageB2bCustomerForm
  },
  data() {
    return {
      selectedCustomerId: null,
    };
  },
}
</script>

<style scoped>
.b2b-customer-manager-header {
  margin: 0;
  background-color: var(--v-lightestAccent-base);
}

.b2b-customer-manager-container {
  background-color: var(--v-lightestAccent-base);
}
</style>